<template>
  <div class="accounting content-index" v-loading="loading">
    <div class="title">
      <el-page-header
        style="padding:10px"
        @back="goBack"
        content="财务月度对账单"
      ></el-page-header>
    </div>
    <div class="password" v-if="loginBool">
      <div class="image">
        <img class="img" src="../../assets/navImages/md-lock@2x.png" alt="" />
      </div>
      <div class="text">请输入访问密码</div>
      <div class="input">
        <el-input
          type="text"
          v-model.trim="password"
          show-password
          maxlength="6"
          show-word-limit
          placeholder="请输入访问密码"
        ></el-input>
        <el-button type="primary" @click="enterPassWord">确定</el-button>
      </div>
    </div>
    
    <div class="content" v-if="!loginBool">
      <div class="search">
        <Search
          :search-data="searchData"
          :defaultBool="true"
          @searchChange="searchChange"
        />
      </div>
      <div class="table">
        <Table
          :button-type="true"
          :current-value="formData.current"
          :header-style="headerStyle"
          :orientation="'center'"
          :table-data="tableData"
          :title-list="titleListData"
          :height="'calc(100vh - 280px)'"
          :total="total"
          @handleCurrentChange="handleCurrentChange"
          @operationClick="operationClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import moment from "moment";
import {
  getMonthlyFinancialStatement,
  checkStorePass,
} from "@/api/store/accounting.js";
import { mapGetters } from "vuex";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      password: "",
      loginBool: true,
      searchData: [
        {
          prop: "billYear",
          label: "年度",
          type: "year",
          format: "yyyy",
          value: moment(new Date()).format("YYYY"),
        },
        {
          prop: "billResult",
          label: "对账结果",
          type: "select",
          width: 180,
          value: 0,
          selectArr: [
            {
              id: 0,
              name: "全部",
            },
            {
              id: 1,
              name: "未确认",
            },
            {
              id: 2,
              name: "有差异",
            },
            {
              id: 3,
              name: "无差异",
            },
          ],
        },
        {
          prop: "paymentResult",
          label: "结算结果",
          type: "select",
          width: 180,
          value: 0,
          selectArr: [
            {
              id: 0,
              name: "全部",
            },
            {
              id: 1,
              name: "未结算",
            },
            {
              id: 2,
              name: "已结算",
            },
          ],
        },
        {
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
              type: "primary",
            },
          ],
        },
      ],
      total: 11,
      formData: {
        current: 1,
        size: 10,
      },
      headerStyle: {
        fontSize: "14px",
        background: "#EFF1F2",
        color: "black",
      },
      titleListData: [
        {
          prop: "billYear",
          label: "年度",
        },
        {
          prop: "billMonth",
          label: "月份",
        },
        {
          prop: "storeName",
          label: "门店",
        },
        {
          prop: "totalIncome",
          label: "收入合计（元）",
        },
        {
          prop: "totalExpenditure",
          label: "支出合计（元）",
        },
        {
          prop: "revenueSpread",
          label: "收/支利差（元）",
        },
        {
          prop: "billResultName",
          label: "对账结果",
        },
        {
          prop: "paymentResultName",
          label: "结算结果",
        },
      ],
      tableData: [],
      loading: false,
    };
  },
  beforeRouteLeave(to, form, next) {
    if (!this.loginBool && to.name != "accountDetails") {
      this.$confirm("你确定离开该页面吗？", "提示", {
        confirmButtonText: "确定离开",
        cancelButtonText: "我再看看",
        type: "warning",
      })
        .then(() => {
          next();
        })
        .catch(() => {});
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters({
      storeUserInfo: "userInfo",
    }),
  },
  watch: {
    $route: {
      handler: function(value) {
        console.log(value);
        if (value.params.bool && value.params.bool == "false") {
          this.loginBool = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (this.loginBool == false) {
      this.searchChange({
        code: "search",
        formData: {
          billYear: moment(new Date()).format("YYYY"),
          searchValue: this.storeUserInfo.storeId,
        },
      });
    }
  },
  methods: {
    goBack() {
      this.$router.push("/home/more");
    },
    enterPassWord() {
      if (this.password) {
        checkStorePass({ password: this.password }).then(({ data }) => {
          if (data.code == 0) {
            if (data.data) {
              this.searchChange({
                code: "search",
                formData: {
                  billYear: moment(new Date()).format("YYYY"),
                  searchValue: this.storeUserInfo.storeId,
                },
              });
              this.loginBool = false;
            } else {
              this.$message.error("密码错误！");
            }
          } else {
            this.$message.error(data.msg);
          }
        });
      } else {
        this.$message.error("密码不能为空！");
      }
    },
    // 获取列表数据
    getMonthlyFinancialStatementList() {
      this.loading = true;
      getMonthlyFinancialStatement({ ...this.formData })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records.map((item) => {
              item.buttonList = [
                {
                  name: "查看",
                  size: "medium",
                  type: "text",
                  code: "look",
                },
              ];
              item.revenueSpread =
                item.revenueSpread && item.totalIncome >= item.totalExpenditure
                  ? "+" + item.revenueSpread
                  : item.revenueSpread;
              return item;
            });
            this.total = data.data.total;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 搜索触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.formData = {
          ...this.formData,
          ...formData,
          billResult: formData.billResult == 0 ? null : formData.billResult,
          paymentResult:
            formData.paymentResult == 0 ? null : formData.paymentResult,
        };
        this.getMonthlyFinancialStatementList();
      } else if (code == "reset") {
        this.formData = {
          current: 1,
          size: 10,
          billYear: moment(new Date()).format("YYYY"),
          searchValue: this.storeUserInfo.storeId,
        };
        this.getMonthlyFinancialStatementList();
      }
    },
    // 改变页码触发
    handleCurrentChange(val) {
      this.formData.current = val;
      this.getMonthlyFinancialStatementList();
    },
    // 点击操作按钮触发
    operationClick({ row, code }) {
      if (code == "look") {
        this.$router.push({
          path: "/home/accountDetails",
          query: {
            id: row.financialBillId,
            bool: this.loginBool,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accounting {
  position: relative;
  padding: 10px 30px 0 30px;

  .title {
    font-size: 24px;
    color: rgba(16, 16, 16, 100);
    padding-bottom: 25px;
  }
  .password {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .image {
      margin-bottom: 20px;
      .img {
        height: 60px;
        width: 60px;
      }
    }
    .text {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      margin-bottom: 20px;
    }
    .input {
      .el-input {
        width: 200px;
        margin-right: 20px;
      }
    }
  }
  .search {
    margin-bottom: 15px;
  }
}
</style>
